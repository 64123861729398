import { FC } from 'react'
import { Text, Box, Flex, Anchor, Button } from '../primitives'
import { Luckiest_Guy } from '@next/font/google'
import { styled } from 'stitches.config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faDiscord, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'

type SectionTitleProps = {
  title: string
}

const SectionTitle: FC<SectionTitleProps> = ({ title }) => (
  <Text style="subtitle1" css={{ color: '$gray12',
  mb: 8,
  fontFamily: "Luckiest Guy, cursive",
  fontSize: "2rem",
  fontWeight: 100,
  letterSpacing: "1px",
  textTransform: "uppercase", }}>
    {title}
  </Text>
)

type SectionLinkProps = {
  name: string
  href: string
}

const luckiest_guy = Luckiest_Guy({
  subsets: ['latin'],
  weight: "400",
})

const SectionLink: FC<SectionLinkProps> = ({ name, href }) => (
  <Anchor
    target="_blank"
    rel="noopener noreferrer"
    href={href}
    weight="medium"
    css={{ fontSize: 14, mt: 16, color: 'white', zIndex: '9999' }}
  >
    {name}
  </Anchor>
)

const homeLinks = [
  {
    name: 'Home',
    href: 'https://hunnys.io/',
  },
  {
    name: 'About',
    href: 'https://hunnys.io/about/',
  },
  {
    name: 'Merch',
    href: 'https://hunnys.io/shopselect/',
  },
]

const companySectionLinks = [
  {
    name: 'Hunnys Closet',
    href: 'https://closet.hunnys.io/',
  },
  {
    name: 'Hunnys Lore',
    href: 'https://hunnys.io/ourlore/',
  },
  {
    name: 'Build Your Own Marketplace',
    href: 'https://hunnys.io/bunnyu/launch-your-own-nft-marketplace/',
  },
]

export const Footer = () => {
  return (
    <Flex
      justify="between"
      css={{
        borderTop: '1px solid $gray7',
        borderStyle: 'solid',
        pt: '$5',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 36,
        '@bp600': {
          flexDirection: 'row',
          gap: 0,
        },
      }}
    >
      <Flex css={{ gap: 80, '@bp600': { gap: 76 } }}>
        <Flex direction="column">
          <SectionTitle title="Hunnys" />
          {homeLinks.map((props) => (
            <SectionLink key={props.name} {...props} />
          ))}
        </Flex>
        <Flex direction="column">
          <SectionTitle title="Explore" />
          {companySectionLinks.map((props) => (
            <SectionLink key={props.name} {...props} />
          ))}
        </Flex>
      </Flex>
      <Flex
        direction="column"
        css={{ zIndex: '9999', textAlign: 'right', alignItems: 'flex-start', '@bp600': { alignItems: 'flex-end' } }}
      >
        <SectionTitle title="Hunnys Community" />
        <Flex css={{ gap: '$4', mt: 16 }}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/hunnysNFT"
          >
            <Button size="xs" color="gray3">
              <FontAwesomeIcon color="deepskyblue" icon={faTwitter} width={14} height={14} />
            </Button>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://discord.gg/hunnys"
          >
            <Button size="xs" color="gray3">
              <FontAwesomeIcon color="cornflowerblue" icon={faDiscord} width={14} height={14} />
            </Button>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/hunnysNFT"
          >
            <Button size="xs" color="gray3">
              <FontAwesomeIcon color="orchid" icon={faInstagram} width={14} height={14} />
            </Button>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://youtube.com/@cryptostacys"
          >
            <Button size="xs" color="gray3">
              <FontAwesomeIcon color="tomato" icon={faYoutube} width={14} height={14} />
            </Button>
          </a>
        </Flex>
      </Flex>
    </Flex>
  )
}
